<template>
  <div class="form-group" :class="groupClass">
    <label class="control-label" v-if="!errorMessage">{{ label }} <span v-if="labelDescription" style="color: #666">{{ labelDescription }}</span></label>
    <div class="help-block" v-if="errorMessage">{{ errorMessage }}</div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: undefined
    },
    label: {
      type: [String],
      default: ''
    },
    labelDescription: {
      type: String,
    },
    errors: {
      type: [String, Array],
      default: () => []
    }
  },
  // mounted() {
  //   if (!this.id) {
  //     this.id = this._uid
  //   }
  // },
  computed: {
    groupClass() {
      return {
        'has-error': this.hasError,
        'has-success': !this.hasError && !this.isEmpty(this.value),
        'has-value': !this.isEmpty(this.value)
      }
    },
    hasValue() {
      return this.value !== undefined
    },
    hasError() {
      return !!this.errors.length
    },
    errorMessage() {
      if (Array.isArray(this.errors)) {
        return this.errors[0] || ''
      } else {
        return this.errors
      }
    },
  },
  methods: {
    isEmpty(value) {
      return value === undefined
          || (Array.isArray(value) && value.length === 0)
          || value === {}
          || value === null
          || value === ''
    }
  }
}
</script>
