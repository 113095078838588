<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 18 18">
    <g fill="none" fill-rule="evenodd" transform="rotate(-90 9 9)">
      <polygon points="0 0 18 0 18 18 0 18"/>
      <polygon class="arrow-svg" :fill="color" points="15.75 8.25 5.122 8.25 7.808 5.558 6.75 4.5 2.25 9 6.75 13.5 7.808 12.443 5.122 9.75 15.75 9.75"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconNext',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    size: {
      type: [String, Number],
      default: 18
    }
  }
}
</script>
