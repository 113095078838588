<template>
  <div class="main-block finish">
    <div class="main-block-content">
      <div class="main-block-title">Verification</div>
      <div class="grid-column-3">
        <form-group label="Verification status" class="has-value">
          <span v-if="registrationData.verification.isCompleted === true">Account verified</span>
          <span v-else-if="registrationData.verification.attributes.token === null" class="my-error">Verification not started</span>
          <span v-else class="my-error">Incompleted</span>
        </form-group>
      </div>

      <hr>

      <div class="main-block-title">Contact info</div>

      <div class="grid-column-3" v-if="person" data-test="director">

        <form-group data-test="person_first_name" label="First Name" :value="person.first_name" :errors="personErrors.first_name">
          {{ person.first_name }}
        </form-group>

        <form-group data-test="person_last_name" label="Last Name" :value="person.last_name" :errors="personErrors.last_name">
          {{ person.last_name }}
        </form-group>

        <form-group data-test="person_middle_name" label="Middle Name" :value="person.middle_name" v-if="person.middle_name" :errors="personErrors.middle_name">
          {{ person.middle_name }}
        </form-group>
      </div>

      <hr>

      <div class="main-block-title">Address</div>

      <div class="grid-column-3" v-if="person" data-test="address">

        <form-group data-test="person_country" label="Country" :value="getCountryById(person.country_id)" :errors="personErrors.country_id">
          {{ getCountryById(person.country_id) }}
        </form-group>

        <form-group data-test="person_city" label="City" :value="person.city" :errors="personErrors.city">
          {{ person.city }}
        </form-group>

        <form-group data-test="person_street" label="Street" :value="person.street" :errors="personErrors.street">
          {{ person.street }}
        </form-group>

        <form-group data-test="person_house" label="House" :value="person.house" :errors="personErrors.house">
          {{ person.house }}
        </form-group>

        <form-group data-test="person_flat" label="Flat" v-if="person.apartment" :value="person.apartment" :errors="personErrors.apartment">
          {{ person.apartment }}
        </form-group>

        <form-group data-test="person_postal_code" label="Postal Code" :value="person.postal_code" :errors="personErrors.postal_code">
          {{ person.postal_code }}
        </form-group>
      </div>

      <hr>

      <template v-if="!isVerified">
        <div class="main-block-title">Identity documents</div>
        <div v-if="walletIdentityFiles.length" class="grid-column-3">
          <form-group v-for="file in walletIdentityFiles" :value="file.name" :key="file.name" :label="getIdentityTypeByID(file.type)" data-test="walletIdentityFiles">
            <a target="_blank" :href="file.url" v-if="file.name">
              <icon-attach color="#19191C" class="file-attach"/>
              <span>{{ file.original_name }}</span>
            </a>
          </form-group>

          <form-group v-if="personErrors.id_document_files" :errors="personErrors.id_document_files"></form-group>
        </div>
        <div v-else class="grid-column-3-1">
          <div class="custom-error">No identity documents added</div>
        </div>

        <hr>
      </template>

      <div class="main-block-title">Address confirmation files</div>
      <div v-if="personAddressFiles.length" class="grid-column-3">
        <form-group v-for="file in personAddressFiles" :value="file.name" :key="file.name" :label="getAddressTypeByID(file.type)" data-test="walletAddressFiles">
          <a target="_blank" :href="file.url" v-if="file.name">
            <icon-attach color="#19191C" class="file-attach"/>
            <span>{{ file.original_name }}</span>
          </a>
        </form-group>

        <form-group v-if="personErrors.address_confirmation_files" :errors="personErrors.address_confirmation_files"></form-group>
      </div>
      <div v-else class="grid-column-3-1">
        <div class="custom-error">No address confirmation files added</div>
      </div>

      <hr>

      <div class="main-block-title">Additional documents</div>
      <div v-if="personResidencePermitFiles.length" class="grid-column-3">
        <form-group v-for="file in personResidencePermitFiles" :value="file.name" :key="file.name" :label="getResidencePermitTypeByID(file.type)" data-test="personResidencePermitFiles">
          <a target="_blank" :href="file.url" v-if="file.name">
            <icon-attach color="#19191C" class="file-attach"/>
            <span>{{ file.original_name }}</span>
          </a>
        </form-group>

        <form-group v-if="personErrors.residence_permit_files" :errors="personErrors.residence_permit_files"></form-group>
      </div>
      <div v-else class="grid-column-3-1">
        <div class="custom-error">No additional files added</div>
      </div>

      <hr>

      <div class="main-block-title">PEP/FATCA</div>

      <div class="grid-column-3" v-if="person">
        <form-group data-test="person_is_pep" label="Does the Person qualify as a PEP?" :value="getBoolById(person.is_pep)" :errors="personErrors.is_pep">
          {{ getBoolById(person.is_pep) }}
        </form-group>

        <form-group data-test="person_is_us" label="Does the Person qualify as a U.S. person?" :value="getBoolById(person.is_us)" :errors="personErrors.is_us">
          {{ getBoolById(person.is_us) }}
        </form-group>
      </div>

      <hr>

      <div class="form-buttons-finish">
        <div>

          <label class="form-control-custom" style="margin-bottom: 0; font-size: 12px;">
            <input type="checkbox" v-model="form.is_agree" :value="1" data-test="agree">
            <span class="form-control">I agree with <a :href="links.terms_and_conditions" target="_blank" class="bold underline">Terms & Conditions</a></span>
          </label>

        </div>
        <div>

          <button class="btn-next" @click="submit" :disabled="canSave || loading" data-test="submit">
            <span>Finish</span>
            <icon-next color="#19191C" size="15"/>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup";
import IconNext from "@/assets/icons/IconNext";
import IconAttach from "@/assets/icons/IconAttach";
import {mapState} from "vuex";

export default {
  name: 'IndividualFinish',
  components: {
    FormGroup,
    IconNext,
    IconAttach
  },
  data () {
    return {
      form: {
        is_agree: null
      },
      loading: false,
    }
  },
  computed: {
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      links: state => state.init.links,

      countries: state => state.init.countries,
      genders: state => state.init.genders,
      boolAnswers: state => state.init.boolAnswers,
      addressTypeList: state => state.init.companyIndividualAddressConfirmationFileType,
      residencePermitTypeList: state => state.init.companyIndividualResidencePermitFileType,
      identityTypeList: state => state.init.companyIndividualIDDocumentFileType,
    }),

    personErrors () {
      return this.registrationData['company-individual'].errors
    },
    person () {
      return this.registrationData['company-individual'].attributes
    },
    canSave () {
      return !this.form.is_agree
    },
    personAddressFiles () {
      return this.person.address_confirmation_files
    },
    personResidencePermitFiles () {
      return this.person.residence_permit_files
    },
    walletIdentityFiles () {
      return this.person.id_document_files
    },
    isVerified () {
      return this.registrationData.verification.isCompleted
          && this.registrationData.verification.attributes.token
    }
  },
  methods: {
    getAddressTypeByID(id) {
      let item = this.addressTypeList.find(i => i.id === id)
      return item ? item.label : ''
    },
    getResidencePermitTypeByID(id) {
      let item = this.residencePermitTypeList.find(i => i.id === id)
      return item ? item.label : ''
    },
    getCountryById(id) {
      let item = this.countries.find(i => i.id === id)
      return item ? item.label : ''
    },
    getGenderById(id) {
      let item = this.genders.find(i => i.id === id)
      return item ? item.label : ''
    },
    getBoolById(id) {
      let item = this.boolAnswers.find(i => i.id === id)
      return item ? item.label : ''
    },
    getIdentityTypeByID(id) {
      let item = this.identityTypeList.find(i => i.id === id)
      return item ? item.label : ''
    },

    submit () {
      this.loading = true
      this.$sdk.registration.finish(this.registrationHash,{agree: true}).then((response) => {
        if (Array.isArray(response.data) && response.data.length === 0) {
          this.$emit('next-step')

          setTimeout(() => {
            this.$store.dispatch('resetRegistration')
          }, 200)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.custom-error {
  content: 'Not set';
  color: #EA4C17;
  display: inline-block;
  font-size: 13px;
}

.form-group:not(.has-value) {
  &:after {
    .custom-error
  }
}

.finish {
  .form-group {
    line-height: 1.65em;
  }

  a {
    color: #19191C;
    vertical-align: middle;
    overflow-wrap: anywhere;

    span {
      text-decoration: underline;
    }

    &:hover span {
      text-decoration: none;
    }
    &:visited {
      color: #19191C;
    }
  }
  .file-attach {
    margin-right: 5px;
    position: relative;
    bottom: -4px;
  }
}
</style>
